import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref } from "vue"

const _hoisted_1 = { class: "vip-video-parser" }
const _hoisted_2 = { class: "button-group" }
const _hoisted_3 = {
  class: "components-page-header-demo-responsive",
  style: {"border":"1px solid rgb(235, 237, 240)"}
}
const _hoisted_4 = { id: "null-data" }
const _hoisted_5 = { class: "content" }
const _hoisted_6 = { class: "main" }
const _hoisted_7 = { class: "extra" }
const _hoisted_8 = { style: {
              display: 'flex',
              width: 'max-content',
              justifyContent: 'flex-end',
            } }

import { ref } from "vue";
import { message } from "ant-design-vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'testPageOne',
  setup(__props) {

const videoUrl = ref<string>("");

// 解析线路的 URL 前缀
const headUrls = [
  "https://www.ckplayer.vip/jiexi/?url=",
  "https://jx.yparse.com/index.php?url=",
  "https://www.8090g.cn/?url=",
  "https://www.pouyun.com/?url=",
  "https://jx.xmflv.com/?url=",
  "https://im1907.top/?jx=",
  "https://jx.aidouer.net/?url=",
  "https://www.yemu.xyz/?url=",
  "https://jx.m3u8.tv/jiexi/?url=",
  "https://api.qianqi.net/vip/?url=",
];

// 清空输入框
const clearInput = () => {
  videoUrl.value = "";
};

// 打开链接
const openUrl = (index) => {
  const fullUrl = headUrls[index] + encodeURIComponent(videoUrl.value);
  window.open(fullUrl, "_blank");
};

/**
 *  按钮点击的全局提醒
 */
const [messageApi, contextHolder] = message.useMessage();

const info = () => {
  messageApi.info("暂无数据");
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_a_descriptions_item = _resolveComponent("a-descriptions-item")!
  const _component_a_descriptions = _resolveComponent("a-descriptions")!
  const _component_a_statistic = _resolveComponent("a-statistic")!
  const _component_a_page_header = _resolveComponent("a-page-header")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("h2", null, "请输入视频网址：", -1)),
      _createVNode(_component_a_input, {
        value: videoUrl.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((videoUrl).value = $event)),
        placeholder: "请输入视频链接"
      }, null, 8, ["value"]),
      _createVNode(_component_a_button, {
        onClick: clearInput,
        class: "custom-button"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("重置")
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(headUrls, (url, index) => {
          return _createVNode(_component_a_button, {
            class: "custom-button",
            key: index,
            onClick: ($event: any) => (openUrl(index))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 线路" + _toDisplayString(index + 1), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        }), 64))
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_page_header, {
        title: "明凡编程宝典简介",
        "sub-title": "sub",
        onBack: _cache[1] || (_cache[1] = () => _ctx.$router.go(-1))
      }, {
        extra: _withCtx(() => [
          _createVNode(_unref(contextHolder)),
          _createVNode(_component_a_button, {
            key: "3",
            onClick: info
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("查询")
            ])),
            _: 1
          }),
          _createVNode(_component_a_button, {
            key: "2",
            onClick: info
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Operation")
            ])),
            _: 1
          }),
          _createVNode(_component_a_button, {
            key: "1",
            onClick: info,
            type: "primary"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Primary")
            ])),
            _: 1
          })
        ]),
        footer: _withCtx(() => [
          _createVNode(_component_a_tabs, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_tab_pane, {
                key: "1",
                tab: "Details"
              }),
              _createVNode(_component_a_tab_pane, {
                key: "2",
                tab: "Rule"
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_empty)
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_a_descriptions, {
                size: "small",
                column: 2
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_descriptions_item, { label: "作者" }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("MingFan")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_descriptions_item, { label: "Association" }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createElementVNode("a", null, "02196668", -1)
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_descriptions_item, { label: "Creation Time" }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("2025-01-01")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_descriptions_item, { label: "Effective Time" }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("always")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_a_descriptions_item, { label: "Remarks" }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode(" Xinyang City, Henan Province, China ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_a_statistic, {
                  title: "Status",
                  value: "developing",
                  style: {
                marginRight: '32px',
              }
                })
              ])
            ])
          ])
        ]),
        _: 1
      })
    ])
  ], 64))
}
}

})