<template>
  <div class="markdown-body">
    <v-viewer>
      <div v-html="parsedContent"></div>
    </v-viewer>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect } from "vue";
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import "highlight.js/styles/github-dark.css"; // 代码高亮主题

const props = defineProps<{
  filePath: string; // 本地文件路径
  content?: string; // 或直接传入内容
}>();

const md = new MarkdownIt({
  html: true,
  highlight: (code, lang) => {
    const validLang = hljs.getLanguage(lang) ? lang : "plaintext";
    return hljs.highlight(code, { language: validLang }).value;
  },
});

const parsedContent = ref("");

watchEffect(async () => {
  if (props.filePath) {
    const response = await fetch(props.filePath);
    const text = await response.text();
    parsedContent.value = md.render(text);
  } else if (props.content) {
    parsedContent.value = md.render(props.content);
  }
});
</script>

<style>
@import "github-markdown-css";
.markdown-body {
  box-sizing: border-box;
  padding: 20px;
  background: white;
  min-width: 200px;
  max-width: 980px;
  margin: 0 auto;
}
</style>
