import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "home-txt-down" }
const _hoisted_2 = { class: "score-container" }
const _hoisted_3 = { class: "player-header" }
const _hoisted_4 = { class: "player-score" }
const _hoisted_5 = { class: "player-actions" }
const _hoisted_6 = { class: "history" }

import { reactive, ref, watch } from "vue";

const msg = "明凡云开发中，敬请期待";

/**
 * 倒计时组件函数
 *
 */
interface Player {
  name: string;
  score: number;
  locked: boolean;
}

interface HistoryItem {
  type: string;
  time: string;
  description: string;
}

// 玩家数据

export default /*@__PURE__*/_defineComponent({
  __name: 'CountdownPage',
  setup(__props) {

const calculateDeadline = (
  targetYear: number,
  targetMonth: number,
  targetDay: number
) => {
  // 创建一个 Date 对象，表示目标日期
  const targetDate = new Date(targetYear, targetMonth - 1, targetDay); // 月份是从 0 开始的，所以需要减 1
  // 获取目标日期的时间戳
  const targetTimestamp = targetDate.getTime();

  // 获取当前时间的时间戳
  const nowTimestamp = Date.now();

  // 计算距离目标日期的毫秒数
  return Date.now() + targetTimestamp - nowTimestamp;
};

/**
 * 倒计时专升本剩下时间
 */
const topUpDegree = calculateDeadline(2026, 3, 16);

// 新增的代码
const players = reactive<Player[]>([
  { name: "玩家1", score: 0, locked: false },
  { name: "玩家2", score: 0, locked: false },
]);

// 玩家数量
const playerCount = ref<number>(2);

// 监听 playerCount 的变化，确保视图更新
watch(playerCount, (newCount) => {
  console.log(`玩家数量更新为: ${newCount}`);
});

// 操作历史记录
const history = ref<HistoryItem[]>([]);

// 获取上家索引
const getPreviousIndex = (currentIndex: number) => {
  return (currentIndex - 1 + players.length) % players.length;
};

// 记录操作历史
const recordHistory = (type: string, description: string) => {
  history.value.unshift({
    type,
    time: new Date().toLocaleTimeString(),
    description,
  });
};

// 处理分数变更
const updateScores = (
  currentIndex: number,
  currentDelta: number,
  previousDelta: number,
  type: string
) => {
  const currentPlayer = players[currentIndex];
  const previousPlayer = players[getPreviousIndex(currentIndex)];

  currentPlayer.score += currentDelta;
  previousPlayer.score += previousDelta;

  recordHistory(
    type,
    `${currentPlayer.name} [${type}] 得分变更：
    ${currentDelta > 0 ? "+" : ""}${currentDelta}（当前玩家） →
    ${previousDelta > 0 ? "+" : ""}${previousDelta}（上家）`
  );
};

const handleFoul = (index: number) => {
  updateScores(index, -1, 1, "犯规");
};

const handleNormalWin = (index: number) => {
  updateScores(index, 4, -4, "普胜");
};

const handleSpecialWin = (
  index: number,
  type: "小金" | "黄金九" | "大金",
  score: number
) => {
  updateScores(index, score, -score, type);
};

// 标签颜色映射
const getTagColor = (type: string) => {
  const colors: Record<string, string> = {
    犯规: "red",
    普胜: "blue",
    小金: "gold",
    黄金九: "#ffd700",
    大金: "volcano",
    切换: "green",
  };
  return colors[type] || "gray";
};

const lockName = (index: number) => {
  players[index].locked = true;
};

return (_ctx: any,_cache: any) => {
  const _component_a_statistic_countdown = _resolveComponent("a-statistic-countdown")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_statistic = _resolveComponent("a-statistic")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_list = _resolveComponent("a-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", { id: "homePage" }, [
      _createElementVNode("h1", { class: "com_file" }, _toDisplayString(msg))
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_col, {
        span: 24,
        style: {"margin-top":"32px","display":"flex","justify-content":"center","align-items":"center"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_statistic_countdown, {
            title: "距离专升本倒计时",
            value: _unref(topUpDegree),
            format: "D 天 H 时 m 分 s 秒"
          }, null, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(players.slice(0, playerCount.value), (player, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "player-panel"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_input, {
              value: player.name,
              "onUpdate:value": ($event: any) => ((player.name) = $event),
              disabled: player.locked,
              placeholder: `玩家 ${index + 1}`,
              style: _normalizeStyle({ color: player.locked ? 'green' : 'inherit' }),
              class: "player-name"
            }, null, 8, ["value", "onUpdate:value", "disabled", "placeholder", "style"]),
            _createVNode(_component_a_button, {
              type: "primary",
              onClick: () => lockName(index)
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" 确定 ")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_statistic, {
              value: player.score,
              title: "当前得分"
            }, null, 8, ["value"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_a_space, {
              direction: "horizontal",
              style: {"flex-wrap":"wrap","gap":"8px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "dashed",
                  onClick: () => handleFoul(index)
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" 犯规 ")
                  ])),
                  _: 2
                }, 1032, ["onClick"]),
                _createVNode(_component_a_popconfirm, {
                  title: "确定要执行此操作吗？",
                  onConfirm: () => handleNormalWin(index)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, { type: "dashed" }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode(" 普胜")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onConfirm"]),
                _createVNode(_component_a_popconfirm, {
                  title: "确定要执行此操作吗？",
                  onConfirm: () => handleSpecialWin(index, '小金', 7)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, { type: "dashed" }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" 小金")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onConfirm"]),
                _createVNode(_component_a_popconfirm, {
                  title: "确定要执行此操作吗？",
                  onConfirm: () => handleSpecialWin(index, '黄金九', 4)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, { type: "dashed" }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" 黄金九")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onConfirm"]),
                _createVNode(_component_a_popconfirm, {
                  title: "确定要执行此操作吗？",
                  onConfirm: () => handleSpecialWin(index, '大金', 10)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, { type: "dashed" }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode(" 大金")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1032, ["onConfirm"])
              ]),
              _: 2
            }, 1024)
          ])
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_a_list, {
          "data-source": history.value,
          header: "操作记录"
        }, {
          renderItem: _withCtx(({ item }) => [
            _createVNode(_component_a_list_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_tag, {
                  color: getTagColor(item.type)
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.type), 1)
                  ]),
                  _: 2
                }, 1032, ["color"]),
                _createTextVNode(" " + _toDisplayString(item.description) + " @ " + _toDisplayString(item.time), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["data-source"])
      ])
    ])
  ], 64))
}
}

})