import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "markdown-body" }
const _hoisted_2 = ["innerHTML"]

import { ref, watchEffect } from "vue";
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import "highlight.js/styles/github-dark.css"; // 代码高亮主题


export default /*@__PURE__*/_defineComponent({
  __name: 'MarkdownRenderer',
  props: {
    filePath: {},
    content: {}
  },
  setup(__props: any) {

const props = __props;

const md = new MarkdownIt({
  html: true,
  highlight: (code, lang) => {
    const validLang = hljs.getLanguage(lang) ? lang : "plaintext";
    return hljs.highlight(code, { language: validLang }).value;
  },
});

const parsedContent = ref("");

watchEffect(async () => {
  if (props.filePath) {
    const response = await fetch(props.filePath);
    const text = await response.text();
    parsedContent.value = md.render(text);
  } else if (props.content) {
    parsedContent.value = md.render(props.content);
  }
});

return (_ctx: any,_cache: any) => {
  const _component_v_viewer = _resolveComponent("v-viewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_viewer, null, {
      default: _withCtx(() => [
        _createElementVNode("div", { innerHTML: parsedContent.value }, null, 8, _hoisted_2)
      ]),
      _: 1
    })
  ]))
}
}

})