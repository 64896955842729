<template>
  <div id="homePage">
    <h1 class="com_file">{{ msg }}</h1>
  </div>
  <div class="home-txt-down">
    <a-col
      :span="24"
      style="
        margin-top: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <a-statistic-countdown
        title="距离专升本倒计时"
        :value="topUpDegree"
        format="D 天 H 时 m 分 s 秒"
      />
    </a-col>
  </div>

  <!-- 玩家面板 -->
  <div class="score-container">
    <div
      v-for="(player, index) in players.slice(0, playerCount)"
      :key="index"
      class="player-panel"
    >
      <!-- 玩家信息 -->
      <div class="player-header">
        <a-input
          v-model:value="player.name"
          :disabled="player.locked"
          :placeholder="`玩家 ${index + 1}`"
          :style="{ color: player.locked ? 'green' : 'inherit' }"
          class="player-name"
        />
        <a-button type="primary" @click="() => lockName(index)">
          确定
        </a-button>
      </div>

      <!-- 当前得分 -->
      <div class="player-score">
        <a-statistic :value="player.score" title="当前得分" />
      </div>

      <!-- 操作按钮 -->
      <div class="player-actions">
        <a-space direction="horizontal" style="flex-wrap: wrap; gap: 8px">
          <a-button type="dashed" @click="() => handleFoul(index)">
            犯规
          </a-button>

          <a-popconfirm
            title="确定要执行此操作吗？"
            @confirm="() => handleNormalWin(index)"
          >
            <a-button type="dashed"> 普胜</a-button>
          </a-popconfirm>

          <a-popconfirm
            title="确定要执行此操作吗？"
            @confirm="() => handleSpecialWin(index, '小金', 7)"
          >
            <a-button type="dashed"> 小金</a-button>
          </a-popconfirm>

          <a-popconfirm
            title="确定要执行此操作吗？"
            @confirm="() => handleSpecialWin(index, '黄金九', 4)"
          >
            <a-button type="dashed"> 黄金九</a-button>
          </a-popconfirm>

          <a-popconfirm
            title="确定要执行此操作吗？"
            @confirm="() => handleSpecialWin(index, '大金', 10)"
          >
            <a-button type="dashed"> 大金</a-button>
          </a-popconfirm>
        </a-space>
      </div>
    </div>

    <!-- 操作历史 -->
    <div class="history">
      <a-list :data-source="history" header="操作记录">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-tag :color="getTagColor(item.type)">{{ item.type }}</a-tag>
            {{ item.description }} @ {{ item.time }}
          </a-list-item>
        </template>
      </a-list>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref, watch } from "vue";

const msg = "明凡云开发中，敬请期待";

/**
 * 倒计时组件函数
 *
 */
const calculateDeadline = (
  targetYear: number,
  targetMonth: number,
  targetDay: number
) => {
  // 创建一个 Date 对象，表示目标日期
  const targetDate = new Date(targetYear, targetMonth - 1, targetDay); // 月份是从 0 开始的，所以需要减 1
  // 获取目标日期的时间戳
  const targetTimestamp = targetDate.getTime();

  // 获取当前时间的时间戳
  const nowTimestamp = Date.now();

  // 计算距离目标日期的毫秒数
  return Date.now() + targetTimestamp - nowTimestamp;
};

/**
 * 倒计时专升本剩下时间
 */
const topUpDegree = calculateDeadline(2026, 3, 16);

// 新增的代码
interface Player {
  name: string;
  score: number;
  locked: boolean;
}

interface HistoryItem {
  type: string;
  time: string;
  description: string;
}

// 玩家数据
const players = reactive<Player[]>([
  { name: "玩家1", score: 0, locked: false },
  { name: "玩家2", score: 0, locked: false },
]);

// 玩家数量
const playerCount = ref<number>(2);

// 监听 playerCount 的变化，确保视图更新
watch(playerCount, (newCount) => {
  console.log(`玩家数量更新为: ${newCount}`);
});

// 操作历史记录
const history = ref<HistoryItem[]>([]);

// 获取上家索引
const getPreviousIndex = (currentIndex: number) => {
  return (currentIndex - 1 + players.length) % players.length;
};

// 记录操作历史
const recordHistory = (type: string, description: string) => {
  history.value.unshift({
    type,
    time: new Date().toLocaleTimeString(),
    description,
  });
};

// 处理分数变更
const updateScores = (
  currentIndex: number,
  currentDelta: number,
  previousDelta: number,
  type: string
) => {
  const currentPlayer = players[currentIndex];
  const previousPlayer = players[getPreviousIndex(currentIndex)];

  currentPlayer.score += currentDelta;
  previousPlayer.score += previousDelta;

  recordHistory(
    type,
    `${currentPlayer.name} [${type}] 得分变更：
    ${currentDelta > 0 ? "+" : ""}${currentDelta}（当前玩家） →
    ${previousDelta > 0 ? "+" : ""}${previousDelta}（上家）`
  );
};

const handleFoul = (index: number) => {
  updateScores(index, -1, 1, "犯规");
};

const handleNormalWin = (index: number) => {
  updateScores(index, 4, -4, "普胜");
};

const handleSpecialWin = (
  index: number,
  type: "小金" | "黄金九" | "大金",
  score: number
) => {
  updateScores(index, score, -score, type);
};

// 标签颜色映射
const getTagColor = (type: string) => {
  const colors: Record<string, string> = {
    犯规: "red",
    普胜: "blue",
    小金: "gold",
    黄金九: "#ffd700",
    大金: "volcano",
    切换: "green",
  };
  return colors[type] || "gray";
};

const lockName = (index: number) => {
  players[index].locked = true;
};
</script>

<style scoped>
.score-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

.player-panel {
  padding: 20px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  margin-bottom: 20px;
}

.player-header {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 16px;
}

.player-name {
  width: 200px;
}

.player-score {
  margin: 16px 0;
  text-align: center;
}

.player-actions {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.history {
  margin-top: 40px;
}

@media (max-width: 600px) {
  .player-actions a-button {
    flex: 1 1 45%; /* 每个按钮占据45%的宽度，自动换行 */
  }
}
</style>
