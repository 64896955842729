import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "editor" }

import docContent from "@/res/安装VMware-Esxi操作系统.md";
import MarkdownRenderer from "@/components/MarkdownRenderer.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'testPageTwo',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("div", null, "testPageTwo", -1)),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(MarkdownRenderer, {
        content: _unref(docContent),
        "file-path": ""
      }, null, 8, ["content"])
    ])
  ], 64))
}
}

})