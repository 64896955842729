<template>
  <div id="homePage">
    <h1 class="com_file">{{ msg }}</h1>
  </div>
  <div class="home-txt-down">
    <a-col
      :span="24"
      style="
        margin-top: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <a-statistic-countdown
        title="预估下一期开发上线周期"
        :value="deadline"
        format="D 天 H 时 m 分 s 秒"
      />
    </a-col>
  </div>
  <div class="home-txt-down">
    <a-collapse accordion>
      <a-collapse-panel key="1" header="明凡云简介">
        <p>{{ text }}</p>
      </a-collapse-panel>
      <a-collapse-panel
        key="2"
        header="成功的人是从失败中走出来的，而不是从成功中走出来的"
      >
        <p>{{ text2 }}</p>
      </a-collapse-panel>
    </a-collapse>
  </div>

  <div class="home-txt-down">
    <a-upload-dragger
      v-model:fileList="fileList"
      name="file"
      :multiple="true"
      @change="handleChange"
      @drop="handleDrop"
    >
      <p class="ant-upload-drag-icon">
        <inbox-outlined></inbox-outlined>
      </p>
      <p class="ant-upload-text">明凡云上传样例</p>
      <p class="ant-upload-hint">这是一个上传demo样例，等待后端API接入。</p>
    </a-upload-dragger>
  </div>
  <div class="home-time-line">
    <a-timeline>
      <a-timeline-item>2025-01-08 明凡云项目规划</a-timeline-item>
      <a-timeline-item>2025-01-10 明凡云项目启动</a-timeline-item>
      <a-timeline-item>2025-02-8 明凡云项目雏形完工</a-timeline-item>
      <a-timeline-item>2025-02-10 明凡云项目进入下一个阶段</a-timeline-item>
    </a-timeline>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { InboxOutlined } from "@ant-design/icons-vue";
import type { UploadChangeParam } from "ant-design-vue";
import { message } from "ant-design-vue";

const msg = "明凡云开发中，敬请期待";
const text = `明凡云-明凡智能云—— 咸鱼梦想家，让梦想照进现实`;
const text2 = `亨利·福特是美国的工业家、工程师、发明家，也是福特汽车公司的创始人。他的这句名言强调了失败对于成功的重要性。福特本人在成功之前也经历了多次失败，他的早期汽车公司如底特律汽车公司和亨利·福特公司都未能成功。但他没有放弃，最终在1903年与其他人一起创立了福特汽车公司，并推出了革命性的T型车，这不仅改变了汽车行业，也推动了现代工业生产方式的发展`;

const fileList = ref([]);
const handleChange = (info: UploadChangeParam) => {
  const status = info.file.status;
  if (status !== "uploading") {
    console.log(info.file, info.fileList);
  }
  if (status === "done") {
    message.success(`${info.file.name} file uploaded successfully.`);
  } else if (status === "error") {
    message.error(`${info.file.name} file upload failed.`);
  }
};
function handleDrop(e: DragEvent) {
  console.log(e);
}

/**
 *   OBS 文件上key value
 *   access_key_id: process.env.1DTJLPUWDVCZDBKB8HZ3,
 *   secret_access_key: process.env.rkhQTsDMA85gAlQlBQeLhD91PWrmCrlBJuBmtiBx,
 */

/**
 * 倒计时组件函数
 *
 */
const calculateDeadline = (
  targetYear: number,
  targetMonth: number,
  targetDay: number
) => {
  // 创建一个 Date 对象，表示目标日期
  const targetDate = new Date(targetYear, targetMonth - 1, targetDay); // 月份是从 0 开始的，所以需要减 1
  // 获取目标日期的时间戳
  const targetTimestamp = targetDate.getTime();

  // 获取当前时间的时间戳
  const nowTimestamp = Date.now();

  // 计算距离目标日期的毫秒数
  return Date.now() + targetTimestamp - nowTimestamp;
};
/**
 * 倒计时下一期开发上线周期
 */
const deadline = calculateDeadline(2025, 4, 1);
</script>

<style scoped>
.com_file {
  margin-bottom: 40px;
}
.home-txt-down {
  margin-bottom: 40px;
  padding: 0 100px;
}
@media (max-width: 768px) {
  .home-txt-down {
    padding: 0 15px;
  }
}
#homePage {
}

.home-time-line {
  display: flex;
  margin-top: 140px; /* 在上方留出 30px 的空白 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
</style>
