<template>
  <!-- 嵌入外部网页并铺满页面 -->
  <iframe
    src="https://md.doocs.org/"
    style="width: 100%; height: 90vh; border: none"
    allowfullscreen
  ></iframe>
</template>

<script setup lang="ts"></script>

<style scoped>
/* 去除页面默认的外边距和内边距 */
body {
  margin: 0;
  padding: 0;
}
</style>
